import styled from "styled-components";
import React from "react";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "../../data";

interface IconButtonProps {
    icon: IconDefinition;
    onClick: () => void;
    isDisabled: boolean;
    theme?: Theme;
}

export const IconButton: React.FC<IconButtonProps> = ({
    icon,
    onClick,
    isDisabled,
    theme
}) => {
    return <StyledButton
        onClick={onClick}
        disabled={isDisabled}
    >
        <FontAwesomeIcon icon={icon} size="2x" color={theme?.wallColor}/>
    </StyledButton>
}

const StyledButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    width: 52px;
    height: 52px;
    margin: -10px;
`;