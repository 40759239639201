import React from "react";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import {IconButton} from "../IconButton";
import {faBackward, faForward, faList, faSlidersH, faStream} from "@fortawesome/free-solid-svg-icons";
import {uppercaseFirst} from "../../utils";
import {BoardView} from "../BoardView";
import {Direction, Grid, Level, Path, Position, Theme} from "../../data";
import styled from "styled-components";

interface GamePageProps {
    level: Level;
    openLevelSelect: () => void;
    openSettings: () => void;
    openHighScores: () => void;
    theme: Theme;
    grid: Grid;
    path: Path;
    onClickCell: (position: Position) => void;
    onDoubleClick: () => void;
    onMove: (direction: Direction) => void;
    isSolved: boolean;
    onAdvanceLevel: () => void;
    onRestart: () => void;
}

export const GamePage: React.FC<GamePageProps> = ({
    level,
    openLevelSelect,
    openSettings,
    openHighScores,
    theme,
    grid,
    path,
    onClickCell,
    onDoubleClick,
    onMove,
    isSolved,
    onAdvanceLevel,
    onRestart,
}) => {
    return (
        <Layout>
            <Header>
                <IconButton icon={faStream} onClick={openLevelSelect} isDisabled={false} theme={theme} />
                <Title>{uppercaseFirst(level.difficulty)} Level {level.number + 1}</Title>
                <IconButton theme={theme} icon={faSlidersH} onClick={openSettings} isDisabled={false} />
            </Header>
            <Main>
                <GameArea>
                    <BoardView
                        theme={theme}
                        grid={grid}
                        path={path}
                        onClickCell={onClickCell}
                        onDoubleClick={onDoubleClick}
                        onMove={onMove}
                    />
                </GameArea>
            </Main>
            <Footer>
                <IconButton theme={theme} icon={faList} onClick={openHighScores} isDisabled={false} />
                { isSolved ? <IconButton
                    theme={theme}
                    icon={faForward}
                    onClick={onAdvanceLevel}
                    isDisabled={!isSolved}
                /> : <IconButton
                    theme={theme}
                    icon={faBackward}
                    onClick={onRestart}
                    isDisabled={path.length === 0}
                /> }
            </Footer>
        </Layout>
    );
}

const GameArea = styled.div`
    flex-grow: 1;
    overflow: hidden;
    margin: 10px;
`;