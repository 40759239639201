import {Grid} from "../../data";

const MINI_GRIDS: Grid[] = [
    [
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
        ["filled", "empty", "empty"],
    ],
    [
        ["filled", "empty", "empty"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "filled"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "filled"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "filled", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "filled", "filled"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "filled", "empty"],
        ["empty", "filled", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["filled", "filled", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "filled", "empty"],
        ["empty", "filled", "empty"],
    ],
    [
        ["filled", "empty", "empty"],
        ["empty", "filled", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "filled"],
        ["empty", "filled", "empty"],
        ["empty", "empty", "empty"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "filled", "empty"],
        ["empty", "empty", "filled"],
    ],
    [
        ["empty", "empty", "empty"],
        ["empty", "filled", "empty"],
        ["filled", "empty", "empty"],
    ],
    [
        ["filled", "empty", "empty"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "filled"],
    ],
    [
        ["empty", "empty", "filled"],
        ["empty", "empty", "empty"],
        ["filled", "empty", "empty"],
    ],
    [
        ["filled", "filled", "empty"],
        ["empty", "empty", "empty"],
        ["empty", "empty", "filled"],
    ],
    [
        ["empty", "empty", "filled"],
        ["empty", "empty", "filled"],
        ["filled", "empty", "empty"],
    ],
    [
        ["empty", "empty", "filled"],
        ["empty", "empty", "empty"],
        ["filled", "filled", "empty"],
    ],
    [
        ["filled", "empty", "empty"],
        ["filled", "empty", "empty"],
        ["empty", "empty", "filled"],
    ],
]

export function getMiniGrid(): Grid {
    return MINI_GRIDS[Math.floor(Math.random() * MINI_GRIDS.length)];
}