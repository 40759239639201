import React, {useState} from "react";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import {IconButton} from "../IconButton";
import {faBackward, faChevronLeft, faForward, faTimes} from "@fortawesome/free-solid-svg-icons";
import {BoardView} from "../BoardView";
import {gameSolve, Grid, Path, positionEquals, Segment, Theme, themeById} from "../../data";
import styled from "styled-components";
import {IconButtonSpacer} from "../IconButtonSpacer";
import {useGrid} from "../../hooks";

interface TutorialProps {
    onClose: () => void;
    isAudioEnabled: boolean;
}

interface Phase {
    grid: Grid;
    messages: string[]
    theme?: Theme;
    isAutoCorneringEnabled?: boolean;
    getHints?: (grid: Grid, path: Path) => Segment[];
    stuckMessage?: string;
    doneMessage?: string;
}

const phases: Phase[] = [
    {
        grid: [
            ["empty", "empty", "filled"],
            ["empty", "empty", "empty"],
            ["empty", "empty", "empty"]
        ],
        messages: [
            "Tap any empty square to begin.",
            "Now tap in the highlighted direction to extend your path.",
            "The path extends until it hits the edge of the grid, a block, or itself.",
            "To complete a level, make a path that fills the whole grid.",
            "To complete a level, make a path that fills the whole grid.",
            "If you ever get stuck, you can back up or press the restart button.",
        ],
        getHints(grid: Grid, path: Path): Segment[] {
            if (path.length === 0) {
                return [];
            }
            const hint = getHint(grid, path);
            return hint ? [hint] : [];
        }
    },
    {
        grid: [
            ["empty", "empty", "filled", "empty"],
            ["empty", "empty", "filled", "empty"],
            ["empty", "empty", "empty", "empty"],
            ["empty", "empty", "filled", "filled"]
        ],
        messages: [
            "This grid's a little harder.",
            "Remember, the path extends until it hits something, even itself!",
            "Remember, the path extends until it hits something, even itself!",
            "You can also swipe to extend the path!",
            "You can also swipe to extend the path!",
        ]
    },
    {
        grid: [
            ["empty", "empty", "empty", "empty"],
            ["empty", "empty", "filled", "empty"],
            ["empty", "empty", "empty", "empty"],
            ["empty", "empty", "filled", "filled"]
        ],
        messages: [
            "You can change your theme in the settings page!",
            "You can change your theme in the settings page!",
            "You can change your theme in the settings page!",
            "This one's called Honey.",
            "This one's called Honey.",
            "There are lots to choose from, so pick your favorite.",
            "There are lots to choose from, so pick your favorite.",
        ],
        theme: themeById("honey")
    },
    {
        grid: [
            ["filled", "filled", "empty", "empty", "empty"],
            ["empty", "empty", "empty", "empty", "empty"],
            ["empty", "filled", "empty", "empty", "empty"],
            ["empty", "empty", "empty", "empty", "empty"]
        ],
        messages: [
            "If enabled, auto cornering will turn corners for you when there's only one way to go.",
            "If enabled, auto cornering will turn corners for you when there's only one way to go.",
        ],
        isAutoCorneringEnabled: true
    },
    {
        grid: [
            ["empty", "empty", "filled", "filled", "empty", "empty"],
            ["empty", "empty", "empty", "empty", "empty", "empty"],
            ["empty", "empty", "empty", "empty", "filled", "empty"],
            ["empty", "empty", "filled", "empty", "empty", "empty"],
            ["empty", "empty", "empty", "empty", "empty", "filled"]
        ],
        messages: [
            "As you progress, the grids will get pretty difficult!",
            "Sometimes you have to make a choice right away.",
            "Then it's pretty straightforward...",
            "Then it's pretty straightforward...",
            "Then it's pretty straightforward...",
            "Then it's pretty straightforward...",
            "...until you have to decide which way to go again.",
            "Think ahead; you can't change directions until you hit something.",
            "Often the hardest part is picking where to start.",
            "Often the hardest part is picking where to start.",
            "Finish this level to complete the tutorial!",
        ],
        theme: themeById("mist"),
        stuckMessage: "Oops, that won't work; try something else.",
        doneMessage: "Tap the continue button to exit the tutorial.",
        getHints(grid: Grid, path: Path): Segment[] {
            if (path.length === 0) {
                return [{ start: { row: 3, column: 1 }, end: { row: 3, column: 1}}];
            } else if (path.length === 1) {
                if (positionEquals(path[0].end, { row: 3, column: 1 })) {
                    return [
                        { start: { row: 3, column: 1 }, end: { row: 0, column: 1 }},
                        { start: { row: 3, column: 1 }, end: { row: 3, column: 0 }},
                        { start: { row: 3, column: 1 }, end: { row: 4, column: 1 }},
                    ]
                } else if (positionEquals(path[0].end, { row: 4, column: 0 })) {
                    return [
                        { start: { row: 4, column: 0 }, end: { row: 0, column: 0 }},
                        { start: { row: 4, column: 0 }, end: { row: 4, column: 4 }},
                    ]
                }
            } else if (path.length === 6 && positionEquals(path[5].end, { row: 3, column: 4 })) {
                return [
                    { start: { row: 3, column: 4 }, end: { row: 3, column: 3 }},
                    { start: { row: 3, column: 4 }, end: { row: 3, column: 5 }},
                ]
            }
            const hint = getHint(grid, path);
            return hint ? [hint] : [];
        }
    }
]

export const Tutorial: React.FC<TutorialProps> = ({
    onClose,
    isAudioEnabled
}) => {
    const [phaseNumber, setPhaseNumber] = useState(0);
    const phase = phases[phaseNumber];

    const {
        path,
        move,
        clickCell,
        doubleClickGrid,
        isSolved,
        restart
    } = useGrid(phase.grid, {
        isAutoCorneringEnabled: phase.isAutoCorneringEnabled || false,
        isAudioEnabled,
        isDoubleClickRestartEnabled: false
    });

    const solution = gameSolve(phase.grid, path);
    const hints = isSolved
        ? []
        : phase.getHints
            ? phase.getHints(phase.grid, path)
            : solution
                ? [solution[path.length]]
                : [];

    const message = isSolved
        ? phase.doneMessage || "Tap the continue button to move to the next level."
        : solution === undefined
            ? phase.stuckMessage || "Press the restart button to start over, or just back up."
            : phase.messages[Math.min(path.length, phase.messages.length - 1)];

    const theme = phase.theme || themeById("earth");

    const onAdvanceLevel = () => {
        restart();
        if (phaseNumber + 1 < phases.length) {
            setPhaseNumber(phaseNumber + 1);
        } else {
            onClose();
        }
    }

    const onBacktrackLevel = () => {
        restart();
        setPhaseNumber(phaseNumber - 1);
    }

    return (
        <Layout>
            <Header>
                { phaseNumber > 0
                    ? <IconButton icon={faChevronLeft} onClick={onBacktrackLevel} isDisabled={false} />
                    : <IconButtonSpacer/>
                }
                <Title>Tutorial</Title>
                <IconButton icon={faTimes} onClick={onClose} isDisabled={false} />
            </Header>
            <Main>
                <Content>
                    <Paragraph>{message}</Paragraph>
                    <GameArea><BoardView
                        theme={theme}
                        grid={phase.grid}
                        path={path}
                        onClickCell={clickCell}
                        onDoubleClick={doubleClickGrid}
                        onMove={move}
                        hints={hints}
                    /></GameArea>
                </Content>
            </Main>
            <Footer>
                <IconButtonSpacer />
                { isSolved ? <IconButton
                    theme={theme}
                    icon={faForward}
                    onClick={onAdvanceLevel}
                    isDisabled={!isSolved}
                /> : <IconButton
                    theme={theme}
                    icon={faBackward}
                    onClick={restart}
                    isDisabled={path.length === 0}
                /> }
            </Footer>
        </Layout>
    );
}

function getHint(grid: Grid, path: Path): Segment | undefined {
    const solution = gameSolve(grid, path);
    return solution && solution[path.length];
}

const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;   
`;


const Paragraph = styled.p`
    line-height: 24px;
    padding-top: 15px;
    height: 50px;
    width: 100%;
    text-align: center;
`;

const GameArea = styled.div`
    flex-grow: 1;
    overflow: hidden;
    margin: 10px;
`;