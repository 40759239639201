import {Position, positionEquals, positionInDirection} from "./Position";
import {Cell} from "./Cell";
import {Direction} from "./Direction";
import {Path, pathLastPosition, pathPositions} from "./Path";
import {Segment} from "./Segment";

export type Grid = Cell[][];

export function gridRowCount(grid: Grid): number {
    return grid.length;
}

export function gridColumnCount(grid: Grid): number {
    return grid[0]?.length || 0;
}

export function gridHasPosition(grid: Grid, position: Position): boolean {
    const numColumns = gridColumnCount(grid);
    const numRows = gridRowCount(grid);

    return position.row >= 0
        && position.row < numRows
        && position.column >= 0
        && position.column < numColumns;
}

export function gridCellAtPosition(grid: Grid, position: Position): Cell {
    return grid[position.row][position.column];
}

export function gridSegmentInDirection(grid: Grid, path: Path, direction: Direction): Segment | undefined {
    const start = pathLastPosition(path);
    // If the board does not yet contain any paths
    if (start === undefined) {
        return undefined;
    }

    const coveredCells = pathPositions(path);
    let current = start;
    while (true) {
        const next = positionInDirection(current, direction);
        // If the next position would be beyond the bounds of the board
        if (!gridHasPosition(grid, next)) {
            break;
        }

        const cellAtNextPosition = gridCellAtPosition(grid, next);

        // If the next position is filled
        if (cellAtNextPosition === "filled") {
            break;
        }

        // Return true iff no path covers this position
        if (coveredCells.some(position => positionEquals(position, next))) {
            break;
        }

        current = next;
    }

    if (current === start) {
        return undefined;
    } else {
        return { start, end: current };
    }
}

export function gridCells(grid: Grid): { cell: Cell, position: Position }[] {
    return grid.flatMap((columns, row) => {
        return columns.flatMap((cell, column) => ({
            cell,
            position: { row, column }
        }))
    })
}