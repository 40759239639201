import {Theme, THEMES} from "../data";

interface UseUnlockedThemesResult {
    unlockedThemes: Theme[];
}

interface UseUnlockedThemesProps {
    levelsSolved: {
        easy: number;
        medium: number;
        hard: number;
    };
    easterEggFound: boolean;
}

export function useUnlockedThemes({ levelsSolved, easterEggFound }: UseUnlockedThemesProps): UseUnlockedThemesResult {
    const unlockedThemes = THEMES.filter(theme => theme.isUnlocked({ levelsSolved, easterEggFound }));
    return { unlockedThemes };
}