import {Direction, directionOpposite, directionTurnLeft, directionTurnRight} from "./Direction";
import {Position, positionEquals, positionInDirection} from "./Position";
import {Grid} from "./Grid";
import { mulberry32 } from "../utils";

interface GeneratorCell {
    time: number;
    inDirection: Direction | undefined;
    outDirection: Direction | undefined;
    isRightActive: boolean;
    isLeftActive: boolean;
}

function generatorNewCell(): GeneratorCell {
    return {
      time: 0,
      inDirection: undefined,
      outDirection: undefined,
      isLeftActive: false,
      isRightActive: false
    };
}

interface ActiveEntry {
    position: Position;
    isLeftTurn: boolean;
}

interface GeneratorState {
    grid: GeneratorCell[][];
    rowCount: number;
    columnCount: number;
    startPosition: Position;
    endPosition: Position;
    pathLength: number;
    activeEntries: ActiveEntry[];
    random: (max: number) => number;
}

function generatorInitialState(rowCount: number, columnCount: number, seed: number): GeneratorState {
    const grid: GeneratorCell[][] = [];
    for (let row = 0; row < rowCount; row++) {
        const newRow = [];
        for (let column = 0; column < columnCount; column++) {
            newRow.push(generatorNewCell());
        }
        grid.push(newRow);
    }
    return {
        grid,
        rowCount,
        columnCount,
        startPosition: { row: 0, column: 0},
        endPosition: { row: 0, column: 0},
        pathLength: 0,
        activeEntries: [],
        random: mulberry32(seed)
    }
}

// 0 -> up
// 1 -> right
// 2 -> down
// 3 -> left

export function generatorHasPosition(state: GeneratorState, position: Position): boolean {
    const numColumns = state.columnCount;
    const numRows = state.rowCount;

    return position.row >= 0
        && position.row < numRows
        && position.column >= 0
        && position.column < numColumns;
}

function generatorCellAtPosition(state: GeneratorState, position: Position): GeneratorCell | undefined {
    if (generatorHasPosition(state, position)) {
        return state.grid[position.row][position.column];
    } else {
        return undefined;
    }
}

function generatorMoveForward(state: GeneratorState, position: Position): Position {
    const cell = generatorCellAtPosition(state, position);
    if (!cell || cell.outDirection === undefined) {
        return position;
    }

    return positionInDirection(position, cell.outDirection);
}

function generatorIsPositionOpenable(state: GeneratorState, position: Position, time: number): boolean {
    const cell = generatorCellAtPosition(state, position);
    if (!cell || cell.inDirection || cell.outDirection) {
        return false;
    }

    for (const direction of [
        Direction.Up,
        Direction.Right,
        Direction.Down,
        Direction.Left
    ]) {
        const newPosition = positionInDirection(position, direction);
        const cell = generatorCellAtPosition(state, newPosition);
        if (cell && cell.inDirection === direction && cell.time < time) {
            return false;
        }
    }

    return true;
}

function generatorIsClosed(state: GeneratorState, position: Position, time: number): boolean {
    const cell = generatorCellAtPosition(state, position);
    return !cell || cell.inDirection === undefined || cell.time < time;
}

function generatorRenumberTime(state: GeneratorState) {
    const delta = Math.floor(Number.MAX_SAFE_INTEGER / state.pathLength);
    let time = 1;
    let position = state.startPosition;
    while (!positionEquals(position, state.endPosition)) {
        state.grid[position.row][position.column].time = time;
        time += delta;
        position = generatorMoveForward(state, position);
    }
    state.grid[state.endPosition.row][state.endPosition.column].time = time;
}

function generatorAddActivePosition(state: GeneratorState, position: Position) {
    const cell = generatorCellAtPosition(state, position);
    if (cell !== undefined) {
        if (!cell.isRightActive) {
            cell.isRightActive = true;
            state.activeEntries.push({
                isLeftTurn: false,
                position,
            })
        }
        if (!cell.isLeftActive) {
            cell.isLeftActive = true;
            state.activeEntries.push({
                isLeftTurn: true,
                position,
            })
        }
    }
}

function generatorAddActivePositionsAround(state: GeneratorState, position: Position) {
    for (let rowOffset = -2; rowOffset <= 2; rowOffset++) {
        for (let columnOffset = -2; columnOffset <= 2; columnOffset++) {
            generatorAddActivePosition(state, {
                row: position.row + rowOffset,
                column: position.column + columnOffset
            });
        }
    }
}

function generatorExtendAtPosition(state: GeneratorState, position: Position, isLeftTurn: boolean) {
    let ok = false;
    const position0 = position;
    const position1 = generatorMoveForward(state, position0);
    const cell0 = generatorCellAtPosition(state, position0);
    const cell1 = generatorCellAtPosition(state, position1);
    if (!cell0) throw new Error("Expected cell0 to be valid");
    if (!cell1) throw new Error("Expected cell1 to be valid");
    if (cell0.outDirection && cell1.outDirection) {
        if (cell0.outDirection === cell1.outDirection) {
            // straight
            let time = cell0.time;
            const directionU = cell0.outDirection;
            const directionV = isLeftTurn
                ? directionTurnLeft(directionU)
                : directionTurnRight(directionU);
            const position2 = positionInDirection(position0, directionV);
            const position3 = positionInDirection(position2, directionV);
            const position4 = positionInDirection(position2, directionU);
            const position5 = positionInDirection(position4, directionU);
            const position6 = positionInDirection(position5, directionU);
            const position7 = positionInDirection(position1, directionU);
            const position8 = positionInDirection(position7, directionOpposite(directionV));
            const cell7 = generatorCellAtPosition(state, position7);
            if (!cell7) throw new Error("Expected cell7 to be valid");
            // TODO crs need to double check this logic
            if (generatorIsPositionOpenable(state, position2, time)
                && generatorIsClosed(state, position3, time)
                && generatorIsPositionOpenable(state, position4, time)
                && generatorIsPositionOpenable(state, position5, time)
                && generatorIsClosed(state, position6, time)
                && (
                    cell7.outDirection !== directionU ||
                    generatorIsClosed(state, position8, time))
            ) {
                let timeDelta = cell7.time - time;
                if (timeDelta < 4) {
                    generatorRenumberTime(state);
                    time = cell0.time;
                    timeDelta = cell7.time - time;
                    if (timeDelta < 4) throw new Error("Time is too compacted");
                }
                cell0.outDirection = directionV;
                cell7.inDirection = directionV;
                const cell2 = generatorCellAtPosition(state, position2);
                const cell4 = generatorCellAtPosition(state, position4);
                const cell5 = generatorCellAtPosition(state, position5);
                const cell9 = generatorCellAtPosition(state, positionInDirection(position0, directionU));
                if (!cell2 || !cell4 || !cell5 || !cell9) {
                    throw new Error("Expected cell2, cell4, cell5, cell9 to be valid");
                }
                cell2.time = time + timeDelta / 4;
                cell2.inDirection = directionOpposite(directionV);
                cell2.outDirection = directionU;
                cell4.time = time + timeDelta / 2;
                cell4.inDirection = directionOpposite(directionU);
                cell4.outDirection = directionU;
                cell5.time = time + timeDelta * 3 / 4;
                cell5.inDirection = directionOpposite(directionU);
                cell5.outDirection = directionOpposite(directionV);
                cell9.time = 0;
                cell9.inDirection = undefined;
                cell9.outDirection = undefined;
                state.pathLength += 2;
                generatorAddActivePositionsAround(state, position1);
                ok = true;
            }
        } else {
            // corner
            let time = cell1.time;
            const directionU = cell0.outDirection;
            const directionV = cell1.outDirection;
            const position2 = positionInDirection(position1, directionU);
            const position3 = positionInDirection(position2, directionU);
            const position4 = positionInDirection(position2, directionV);
            const position5 = positionInDirection(position4, directionV);
            const position6 = positionInDirection(position4, directionOpposite(directionU));
            const position7 = positionInDirection(position6, directionOpposite(directionU));
            const cell6 = generatorCellAtPosition(state, position6);
            if (!cell6) throw new Error("Expected cell6 to be valid");
            if (generatorIsPositionOpenable(state, position2, time)
                && generatorIsClosed(state, position3, time)
                && generatorIsPositionOpenable(state, position4, time)
                && generatorIsClosed(state, position5, time)
                && (
                    cell6.outDirection !== directionV
                    || generatorIsClosed(state, position7, time)
                )
            ) {
                let timeDelta = cell6.time - time;
                if (timeDelta < 3) {
                    generatorRenumberTime(state);
                    time = cell1.time;
                    timeDelta = cell6.time - time;
                    if (timeDelta < 3) throw new Error("Time is too compact");
                }
                cell1.outDirection = directionU;
                cell6.inDirection = directionU;
                const cell2 = generatorCellAtPosition(state, position2);
                const cell4 = generatorCellAtPosition(state, position4);
                if (!cell2 || !cell4) {
                    throw new Error("Expected cell2, cell4 to be valid");
                }
                cell2.time = time + timeDelta / 3;
                cell2.inDirection = directionOpposite(directionU);
                cell2.outDirection = directionV;
                cell4.time = time + timeDelta * 2 / 3;
                cell4.inDirection = directionOpposite(directionV);
                cell4.outDirection = directionOpposite(directionU);
                state.pathLength += 2;
                generatorAddActivePositionsAround(state, position1);
                ok = true;
            }
        }
    }
    return ok;
}


function generatorCellOpen(state: GeneratorState, position: Position, direction: Direction, length: number): Position {
    let currentPosition = position;
    for (let index = 0; index < length; index++) {
        generatorAddActivePosition(state, currentPosition);
        const cell = generatorCellAtPosition(state, currentPosition);
        if (!cell) throw new Error("Expected cell to be valid");
        cell.outDirection = direction;
        currentPosition = generatorMoveForward(state, currentPosition);
        const cell2 = generatorCellAtPosition(state, currentPosition);
        if (!cell2) throw new Error("Expected cell2 to be valid");
        cell2.inDirection = directionOpposite(direction);
    }
    state.pathLength += length;
    return currentPosition;
}

function getInitialOffsets(state: GeneratorState, rowCount: number, columnCount: number) {
    let rowOffset = 0;
    let columnOffset = 0;
    while (rowOffset < 2 && columnOffset < 2) {
        rowOffset = state.random(Math.min(100, rowCount));
        columnOffset = state.random(Math.min(100, columnCount));
    }
    return { rowOffset, columnOffset };
}

export function gridGenerate(rowCount: number, columnCount: number, seed: number): Grid {
    // console.log(`Generating ${rowCount}x${columnCount} grid with seed ${seed}`);
    const state = generatorInitialState(rowCount, columnCount, seed);
    let loopIndex = 0;
    if (state.pathLength !== 0) throw new Error("Bad starting state");
    // first step
    const { rowOffset, columnOffset } = getInitialOffsets(state, state.rowCount, state.columnCount);
    const row = state.random(state.rowCount - rowOffset);
    const column = state.random(state.columnCount - columnOffset);
    state.pathLength = 1;
    let rowDirection;
    let columnDirection;
    if (state.random(2) === 0) {
        state.startPosition.row = row;
        state.endPosition.row = row + rowOffset;
        rowDirection = Direction.Down;
    } else {
        state.startPosition.row = row + rowOffset;
        state.endPosition.row = row;
        rowDirection = Direction.Up;
    }
    if (state.random(2) === 0) {
        state.startPosition.column = column;
        state.endPosition.column = column + columnOffset;
        columnDirection = Direction.Right;
    } else {
        state.startPosition.column = column + columnOffset;
        state.endPosition.column = column;
        columnDirection = Direction.Left;
    }
    let position = state.startPosition;
    if (state.random(2) === 0) {
        position = generatorCellOpen(state, position, columnDirection, columnOffset);
        generatorCellOpen(state, position, rowDirection, rowOffset);
    } else {
        position = generatorCellOpen(state, position, rowDirection, rowOffset);
        generatorCellOpen(state, position, columnDirection, columnOffset);
    }
    generatorRenumberTime(state);

    while (true) {
        loopIndex+= 1;
        if (loopIndex > 50000) {
            throw new Error("Maximum loops exceeded");
        }
        if (state.activeEntries.length) {
            const activeIndex = state.random(Math.min(
                state.rowCount + state.columnCount,
                state.activeEntries.length
            ));
            const { position, isLeftTurn } = state.activeEntries[activeIndex];
            const extendedSuccessfully = generatorExtendAtPosition(state, position, isLeftTurn);
            if (!extendedSuccessfully) {
                const cell = generatorCellAtPosition(state, position);
                if (!cell) throw new Error("Expected cell to be valid");
                if (isLeftTurn) {
                    cell.isLeftActive = false;
                } else {
                    cell.isRightActive = false;
                }
                state.activeEntries.splice(activeIndex, 1);
            }
        } else {
            // shorten goal
            const cell0 = generatorCellAtPosition(state, state.endPosition);
            if (!cell0) throw new Error("Expected cell0 to be valid");
            const directionU = cell0.inDirection;
            if (!directionU) throw new Error("Expected directionU to be valid -- if this throws, maybe set directionV to be up");
            const directionV = directionTurnRight(directionU);
            const position3 = positionInDirection(state.endPosition, directionV);
            const position4 = positionInDirection(state.endPosition, directionOpposite(directionV));
            const cell3 = generatorCellAtPosition(state, position3);
            const cell4 = generatorCellAtPosition(state, position4);
            if (!(cell3 && cell3.outDirection) && !(cell4 && cell4.outDirection)) {
                generatorAddActivePositionsAround(state, state.endPosition);
                state.endPosition = positionInDirection(state.endPosition, directionU);
                cell0.inDirection = undefined;
                cell0.time = 0;
                const cell1 = generatorCellAtPosition(state, state.endPosition);
                if (!cell1) throw new Error("Expected cell1 to be valid");
                cell1.outDirection = undefined;
                state.pathLength -= 1;
                continue;
            }
            // extend start
            const cell02 = generatorCellAtPosition(state, state.startPosition);
            if (!cell02) throw new Error("Expected cell02 to be valid");
            if (!cell02.outDirection) throw new Error("Expected cell0.outDirection to be valid -- if this throws, maybe set directionU to be up");
            const directionU2 = directionOpposite(cell02.outDirection);
            const position1 = positionInDirection(state.startPosition, directionU2);
            const cell1 = generatorCellAtPosition(state, position1);
            if (cell1 && !cell1.inDirection) {
                cell02.inDirection = directionOpposite(cell02.outDirection);
                cell1.outDirection = cell02.outDirection;
                cell1.time = 1;
                state.startPosition = position1;
                state.pathLength += 1;
                continue;
            }
            const directionV2 = state.random(2) === 0
                ? directionTurnLeft(cell02.outDirection)
                : directionTurnRight(cell02.outDirection);
            const position22 = positionInDirection(state.startPosition, directionV2);
            const position32 = positionInDirection(state.startPosition, directionOpposite(directionV2));
            const cell22 = generatorCellAtPosition(state, position22);
            const cell32 = generatorCellAtPosition(state, position32);
            if (cell22 && !cell22.inDirection && cell32 && !cell32.inDirection) {
                cell02.inDirection = directionV2;
                cell22.outDirection = directionOpposite(directionV2);
                cell22.time = 1;
                state.startPosition = position22;
                state.pathLength += 1;
                continue;
            }
            break;
        }
    }
    // generatorPrintState(state);
    return state.grid.map((row) => {
        return row.map((cell) => {
            if (cell.outDirection || cell.inDirection) {
                return "empty";
            } else {
                return "filled";
            }
        })
    });
}