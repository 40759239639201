import React, {useEffect, useState} from "react";

export function useLocalStorage<T>(key: string, initialValue: T): [T, React.Dispatch<T>] {
    const [value, setValue] = useState(() => {
        const storedValue = localStorage.getItem(key);
        if (storedValue !== null) {
            try {
                return JSON.parse(storedValue);
            } catch (error) {
                console.warn(error);
                return initialValue;
            }
        } else {
            return initialValue;
        }
    });

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
}