export function uppercaseFirst(value: string) {
    return value.substring(0, 1).toUpperCase() + value.substring(1);
}

export function randomRecoveryToken() {
    const availableCharacters = "ABCDEFGHJKLMNPQRSTWYZ234678";
    return 'XXXX-XXXX-XXXX'.replace(/X/g, function() {
        return availableCharacters[Math.floor(Math.random() * availableCharacters.length)];
    });
}

// https://stackoverflow.com/a/47593316
export function mulberry32(seed: number) {
    const rawRandom = function() {
        let t = seed += 0x6D2B79F5;
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }

    return function random(max: number) {
        return Math.floor(rawRandom() * max);
    }
}

export function range(start: number, end: number): number[] {
    const numbers = [];
    for (let i = start; i < end; i++) {
        numbers.push(i);
    }
    return numbers;
}