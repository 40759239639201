import React, {useState} from "react";
import {Theme} from "../../data";
import styled from "styled-components";
import {IconButton} from "../IconButton";
import {faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ThemeSelect} from "../ThemeSelect";
import {IconButtonSpacer, InlineInput, RecoverySettings, ToggleButton} from "..";
import {Credits} from "../Credits";
import {Tutorial} from "../Tutorial";

interface LevelSelectProps {
    unlockedThemes: Theme[],
    setEasterEggFound: (easterEggFound: boolean) => void;
    isAutoCorneringEnabled: boolean;
    setIsAutoCorneringEnabled: (isAutoCorneringEnabled: boolean) => void;
    isLeaderboardEnabled: boolean;
    setIsLeaderboardEnabled: (isLeaderboardEnabled: boolean) => void;
    isAudioEnabled: boolean;
    setIsAudioEnabled: (isAudioEnabled: boolean) => void;
    isDoubleClickRestartEnabled: boolean;
    setIsDoubleClickRestartEnabled: (isDoubleClickRestartEnabled: boolean) => void;
    theme: Theme;
    setTheme: (theme: Theme) => void;
    playerName: string;
    setPlayerName: (playerName: string) => void;
    recoveryToken: string;
    setRecoveryToken: (recoveryToken: string) => void;
    close: () => void;
}

export const Settings: React.FC<LevelSelectProps> = ({
    theme,
    setTheme,
    isAutoCorneringEnabled,
    setIsAutoCorneringEnabled,
    isAudioEnabled,
    setIsAudioEnabled,
    isLeaderboardEnabled,
    setIsLeaderboardEnabled,
    close,
    unlockedThemes,
    playerName,
    setPlayerName,
    setEasterEggFound,
    recoveryToken,
    setRecoveryToken,
    isDoubleClickRestartEnabled,
}) => {
    const [view, setView] = useState("settings");

    if (view === "settings") {
        return <Layout>
            <Header>
                <IconButtonSpacer />
                <Title>Settings</Title>
                <IconButton icon={faTimes} onClick={close} isDisabled={false} />
            </Header>
            <Main>
                <SettingsList>
                    <SettingDiv>
                        <div>Name for high scores</div>
                        <InlineInput value={playerName} setValue={setPlayerName} />
                    </SettingDiv>
                    <SettingDiv>
                        <div>Auto cornering</div>
                        <ToggleButton value={isAutoCorneringEnabled} setValue={setIsAutoCorneringEnabled} theme={theme}/>
                    </SettingDiv>
                    {/*<SettingDiv>*/}
                    {/*    <div>Double tap to restart</div>*/}
                    {/*    <ToggleButton value={isDoubleClickRestartEnabled} setValue={setIsDoubleClickRestartEnabled} theme={theme}/>*/}
                    {/*</SettingDiv>*/}
                    <SettingDiv>
                        <div>Sound effects</div>
                        <ToggleButton value={isAudioEnabled} setValue={setIsAudioEnabled} theme={theme}/>
                    </SettingDiv>
                    <SettingDiv>
                        <div>Send high scores</div>
                        <ToggleButton value={isLeaderboardEnabled} setValue={setIsLeaderboardEnabled} theme={theme}/>
                    </SettingDiv>
                    <SettingMenu onClick={() => setView("themes")}>
                        <div>Theme</div>
                        <div>
                            <ValueDetailLabel>{theme.name}</ValueDetailLabel>
                            <DisclosureIcon />
                        </div>
                    </SettingMenu>
                    <SettingMenu onClick={() => setView("tutorial")}>
                        <div>Tutorial</div>
                        <DisclosureIcon />
                    </SettingMenu>
                    <SettingMenu onClick={() => setView("recovery")}>
                        <div>Recovery token</div>
                        <div>
                            <ValueDetailLabel>{recoveryToken}</ValueDetailLabel>
                            <DisclosureIcon />
                        </div>
                    </SettingMenu>
                    <SettingMenu onClick={() => setView("credits")}>
                        <div>Credits</div>
                        <DisclosureIcon />
                    </SettingMenu>
                    <SettingMenu onClick={() => window.open("https://www.buymeacoffee.com/cglyph")}>
                        <div>Buy me a coffee</div>
                        <DisclosureIcon />
                    </SettingMenu>
                </SettingsList>
            </Main>
            <Footer />
        </Layout>
    } else if (view === "themes") {
        return (
            <ThemeSelect
                unlockedThemes={unlockedThemes}
                setEasterEggFound={setEasterEggFound}
                theme={theme}
                setTheme={setTheme}
                close={() => setView("settings")}
                isAutoCorneringEnabled={isAutoCorneringEnabled}
                isAudioEnabled={isAudioEnabled}
                isDoubleClickRestartEnabled={isDoubleClickRestartEnabled}
            />
        )
    } else if (view === "credits") {
        return (
            <Credits
                close={() => setView("settings")}
            />
        )
    } else if (view === "tutorial") {
        return <Tutorial
            onClose={() => setView("settings")}
            isAudioEnabled={isAudioEnabled}
        />;
    } else {
        return (
            <RecoverySettings
                recoveryToken={recoveryToken}
                setRecoveryToken={setRecoveryToken}
                close={() => setView("settings")}
                theme={theme}
            />
        );
    }
}

const SettingsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

const ValueDetailLabel = styled.span`
    color: gray;
    margin-right: 16px;
    white-space: nowrap;
`;

const SettingDiv = styled.div`
    padding: 10px 15px;   
    display: flex;
    justify-content: space-between;
    height: 32px;
    align-items: center;
    
    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
`;

const SettingMenu = styled(SettingDiv)`
    cursor: pointer;
`;

const DisclosureIcon: React.FC = () => {
    return <DisclosureWrapper><FontAwesomeIcon icon={faChevronRight} /></DisclosureWrapper>;
}

const DisclosureWrapper = styled.span`
    margin-right: 5px;
`;