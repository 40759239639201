import React from "react";
import styled from "styled-components";

interface InlineInputProps {
    value: string;
    setValue: (value: string) => void;
}

export const InlineInput: React.FC<InlineInputProps> = ({ value, setValue }) => {
    return <StyledInput value={value} onChange={(e) => setValue(e.target.value)} />
}

const StyledInput = styled.input`
    background-color: #efefef;
    border: none;
    height: 32px;
    padding: 0px 10px;
    font-size: 14px;
    border-radius: 4px;
    
    :not(:focus) {
        text-align: right;
    }
`;