import React, {useState} from "react";
import {Level, Score, Theme} from "../../data";
import styled from "styled-components";
import { Footer, Header, Layout, Main, Title } from "../../layout";
import {IconButton} from "../IconButton";
import {faSyncAlt, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "..";
import { uppercaseFirst } from "../../utils";

interface HighScoresProps {
    level: Level,
    scores: {
        easy: Score[];
        medium: Score[];
        hard: Score[];
    };
    theme: Theme;
    close: () => void;
    onRefresh: () => void;
}

export const HighScores: React.FC<HighScoresProps> = ({
    level,
    theme,
    scores,
    close,
    onRefresh
}) => {
    const [viewDifficulty, setViewDifficulty] = useState(level.difficulty);

    return <Layout>
        <Header>
            <IconButton icon={faSyncAlt} onClick={onRefresh} isDisabled={false} />
            <Title>{uppercaseFirst(viewDifficulty)} Leaderboard</Title>
            <IconButton icon={faTimes} onClick={close} isDisabled={false} />
        </Header>
        <Main>
            <ListContainer>
                {
                    scores[viewDifficulty]
                        .filter((score) => score.number > 0)
                        .map((score, position) => {
                            return <ScoreItem
                                key={position}
                                theme={theme}
                                score={score}
                                position={position}
                            />;
                        })
                }
            </ListContainer>
        </Main>
        <Footer>
            <div/>
            <DifficultySelect>
                <Button onClick={() => setViewDifficulty("easy")} text="Easy" isDisabled={false} theme={theme} />
                <Button onClick={() => setViewDifficulty("medium")} text="Medium" isDisabled={false} theme={theme} />
                <Button onClick={() => setViewDifficulty("hard")} text="Hard" isDisabled={false} theme={theme} />
            </DifficultySelect>
            <div/>
        </Footer>
    </Layout>;
}

const DifficultySelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`;

const ListContainer = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
`;

interface LevelItemProps {
    score: Score;
    position: number;
    theme: Theme;
}

const ScoreItem: React.FC<LevelItemProps> = ({ score, theme, position }) => {
    return <LevelItemDiv
        theme={theme}
    >
        <PositionDiv>{position + 1}</PositionDiv>
        <div>
            <div>{score.name}</div>
            <LevelNumberDiv>Level {score.number}</LevelNumberDiv>
        </div>
    </LevelItemDiv>;
}

const LevelItemDiv = styled.div<{ theme: Theme }>`
    width: 100%;
    padding: 10px;   
    padding-left: 0px;
    display: flex;
    align-items: center;
    
    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
`;

const PositionDiv = styled.div`
    width: 50px;
    color: gray;
    text-align: center;
`;

const LevelNumberDiv = styled.div`
    color: gray;
`;