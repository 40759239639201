export enum Direction {
    Left = "left",
    Right = "right",
    Up = "up",
    Down = "down"
}

export function directionOpposite(direction: Direction) {
    switch (direction) {
        case Direction.Down:
            return Direction.Up;
        case Direction.Up:
            return Direction.Down;
        case Direction.Right:
            return Direction.Left;
        case Direction.Left:
            return Direction.Right;
    }
}

export function directionTurnLeft(direction: Direction) {
    switch (direction) {
        case Direction.Down:
            return Direction.Right;
        case Direction.Up:
            return Direction.Left;
        case Direction.Right:
            return Direction.Up;
        case Direction.Left:
            return Direction.Down;
    }
}

export function directionTurnRight(direction: Direction) {
    switch (direction) {
        case Direction.Down:
            return Direction.Left;
        case Direction.Up:
            return Direction.Right;
        case Direction.Right:
            return Direction.Down;
        case Direction.Left:
            return Direction.Up;
    }
}