import {Direction, Position} from ".";
import {Grid, gridCellAtPosition, gridColumnCount, gridRowCount, gridSegmentInDirection} from "./Grid";
import {Path, pathPositions} from "./Path";

export function gameCanMoveInDirection(grid: Grid, path: Path, direction: Direction): boolean {
    return gridSegmentInDirection(grid, path, direction) !== undefined;
}

export function gameMoveInDirection(grid: Grid, path: Path, direction: Direction): Path {
    const segment = gridSegmentInDirection(grid, path, direction);
    if (segment !== undefined) {
        return [...path, segment];
    } else {
        return path;
    }
}

export function gameAddStartingPosition(grid: Grid, path: Path, position: Position): Path {
    if (path.length || gridCellAtPosition(grid, position) === "filled") {
        return path;
    } else {
        return [{ start: position, end: position }];
    }
}

export function gameIsSolved(grid: Grid, path: Path): boolean {
    const coveredPositions = pathPositions(path);
    const coverGrid = grid.map(row => [...row]);
    for (const position of coveredPositions) {
        coverGrid[position.row][position.column] = "filled";
    }
    const rowCount = gridRowCount(grid);
    const columnCount = gridColumnCount(grid);
    for (let row = 0; row < rowCount; row++) {
        for (let column = 0; column < columnCount; column++) {
            if (coverGrid[row][column] !== "filled") {
                return false;
            }
        }
    }
    return true;
}

export function gameRestart(): Path {
    return [];
}

export function gamePossibleMoves(grid: Grid, path: Path): Direction[] {
    return [
        Direction.Left,
        Direction.Right,
        Direction.Up,
        Direction.Down
    ].filter(direction => {
        return gridSegmentInDirection(grid, path, direction) !== undefined;
    })
}

export function gameSolve(grid: Grid, path: Path = []): Path | undefined {
    if (gameIsSolved(grid, path)) {
        return path;
    }

    if (path.length === 0) {
        const rowCount = gridRowCount(grid);
        const columnCount = gridColumnCount(grid);
        for (let row = 0; row < rowCount; row++) {
            for (let column = 0; column < columnCount; column++) {
                const position = { row, column };
                if (gridCellAtPosition(grid, position) === "empty") {
                    const solution = gameSolve(grid, [{ start: position, end: position }]);
                    if (solution !== undefined) {
                        return solution;
                    }
                }
            }
        }
    } else {
        for (const direction of [Direction.Left, Direction.Up, Direction.Right, Direction.Down]) {
            if (gameCanMoveInDirection(grid, path, direction)) {
                const segment = gridSegmentInDirection(grid, path, direction);
                if (segment !== undefined) {
                    const solution = gameSolve(grid, [...path, segment]);
                    if (solution !== undefined) {
                        return solution;
                    }
                }
            }
        }
    }
    return undefined;
}