import {Direction} from "./Direction";

export interface Position {
    row: number;
    column: number;
}

export function positionEquals(position: Position, other: Position): boolean {
    return position.row === other.row && position.column === other.column;
}

export function positionKey(position: Position): string {
    return `${position.row}/${position.column}`;
}

export function positionInDirection(position: Position, direction: Direction): Position {
    switch (direction) {
        case Direction.Left:
            return { ...position, column: position.column - 1 };
        case Direction.Right:
            return { ...position, column: position.column + 1 };
        case Direction.Up:
            return { ...position, row: position.row - 1 };
        case Direction.Down:
            return { ...position, row: position.row + 1 };
    }
}

export function bestDirectionBetween(start: Position, end: Position): Direction {
    const columnOffset = start.column - end.column;
    const rowOffset = start.row - end.row;

    if (Math.abs(columnOffset) > Math.abs(rowOffset)) {
        if (columnOffset > 0) {
            return Direction.Left;
        } else {
            return Direction.Right;
        }
    } else {
        if (rowOffset > 0) {
            return Direction.Up;
        } else {
            return Direction.Down;
        }
    }
}