import {Direction} from "./Direction";
import {Position, positionKey} from "./Position";

export interface Segment {
    start: Position;
    end: Position;
}

export function segmentKey(segment: Segment): string {
    return `${positionKey(segment.start)}:${positionKey(segment.end)}`;
}

export function segmentPositions(segment: Segment): Position[] {
    const positions = [];

    for (
        let row = Math.min(segment.start.row, segment.end.row);
        row <= Math.max(segment.start.row, segment.end.row);
        row++
    ) {
        for (
            let column = Math.min(segment.start.column, segment.end.column);
            column <= Math.max(segment.start.column, segment.end.column);
            column++
        ) {
            positions.push({ row, column });
        }
    }

    return positions;
}

export function segmentDirection(segment: Segment): Direction | undefined {
    if (segment.end.row < segment.start.row) {
        return Direction.Up;
    } else if (segment.end.row > segment.start.row) {
        return Direction.Down;
    } else if (segment.end.column < segment.start.column) {
        return Direction.Left;
    } else if (segment.end.column > segment.start.column) {
        return Direction.Right;
    } else {
        return undefined;
    }
}