import {
    bestDirectionBetween,
    Direction,
    gameAddStartingPosition,
    gameCanMoveInDirection,
    gameIsSolved,
    gameMoveInDirection,
    gamePossibleMoves,
    Grid,
    Path, pathLastPosition, pathUndoDirection, pathUndoLastMove,
    Position, positionEquals
} from "../data";
import {useState} from "react";
import {useGameSounds} from "./use_game_sounds";

export interface UseBoardResult {
    path: Path;
    isSolved: boolean;
    restart: () => void;
    move: (direction: Direction) => void;
    clickCell: (position: Position) => void;
    doubleClickGrid: () => void;
}

export interface UseGridOptions {
    isAutoCorneringEnabled: boolean,
    isAudioEnabled: boolean,
    isDoubleClickRestartEnabled: boolean
}

export function useGrid(grid: Grid, {
    isAutoCorneringEnabled,
    isAudioEnabled,
    isDoubleClickRestartEnabled,
}: UseGridOptions): UseBoardResult {
    const [path, setPath] = useState<Path>([]);

    const { playSwipe, playWin } = useGameSounds();

    const restart = () => {
        setPath([])
    }

    const isSolved = gameIsSolved(grid, path);

    const undo = (continuationPath = path) => {
        const newPath = pathUndoLastMove(continuationPath);
        setPath(newPath);
        if (isAutoCorneringEnabled) {
            const possibleNextMoves = gamePossibleMoves(grid, newPath);
            if (possibleNextMoves.length === 1) {
                setTimeout(() => undo(newPath), 25);
            }
        }
    }

    const move = (direction: Direction, continuationPath = path) => {
        if (gameCanMoveInDirection(grid, continuationPath, direction)) {
            const newPath = gameMoveInDirection(grid, continuationPath, direction);
            isAudioEnabled && continuationPath === path && playSwipe();
            setPath(newPath);
            if (isAudioEnabled && gameIsSolved(grid, newPath)) {
                playWin();
            }
            if (isAutoCorneringEnabled) {
                const possibleNextMoves = gamePossibleMoves(grid, newPath);
                if (possibleNextMoves.length === 1) {
                    setTimeout(() => move(possibleNextMoves[0], newPath), 25);
                }
            }
        } else if (direction === pathUndoDirection(continuationPath)) {
            undo();
        }
    }

    const start = (position: Position) => {
        setPath(gameAddStartingPosition(grid, path, position))
    }

    const clickCell = (position: Position) => {
        if (path.length === 1 && positionEquals(position, path[0].start)) {
            restart();
        } else if (path.length === 0) {
            start(position);
        } else {
            const currentPosition = pathLastPosition(path);
            const direction = currentPosition && bestDirectionBetween(currentPosition, position);
            if (direction) {
                move(direction);
            }
        }
    }

    const doubleClickGrid = () => {
        if (isDoubleClickRestartEnabled) {
            restart();
        }
    }

    return {
        path,
        isSolved,
        restart,
        move,
        clickCell,
        doubleClickGrid
    };
}