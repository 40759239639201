import {Segment, segmentDirection, segmentPositions} from "./Segment";
import {Direction, directionOpposite} from "./Direction";
import { Position } from "./Position";

export type Path = Segment[];

export function pathLastPosition(path: Path): Position | undefined {
    return path.length > 0
        ? path[path.length - 1].end
        : undefined;
}

export function pathPositions(path: Path): Position[] {
    return path.flatMap(segmentPositions);
}

export function pathUndoDirection(path: Path): Direction | undefined {
    const lastSegment = path[path.length - 1];
    const previousDirection = lastSegment && segmentDirection(lastSegment);
    return previousDirection && directionOpposite(previousDirection);
}

export function pathUndoLastMove(path: Path): Path {
    return path.slice(0, path.length - 1);
}