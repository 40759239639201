export interface ThemeConditionState {
    levelsSolved: {
        easy: number;
        medium: number;
        hard: number;
    };
    easterEggFound: boolean;
}

export interface Theme {
    name: string;
    id: string;
    wallColor: string;
    pathColor: string;
    tileColor: string;
    isUnlocked: (state: ThemeConditionState) => boolean;
}

export function themeInverse(theme: Theme) {
    return {
        ...theme,
        name: `Inverted ${theme.name}`,
        id: `inverted-${theme.id}`,
        wallColor: theme.pathColor,
        pathColor: theme.wallColor
    }
}

const UNLOCKED_INITIALLY = (_state: ThemeConditionState) => true;

const COLOR_NAMES = {
    oceanBlue: "rgb(76, 140, 191)",
    tileGray: "rgb(229, 229, 229)",
    landGreen: "rgb(135, 186, 135)",
    slateGray: "rgb(103, 117, 139)",
    cardinalRed: "rgb(171, 35, 52)",
    lightSlateGray: "rgb(99, 137, 163)",
    royalRed: "rgb(193, 66, 51)",
    royalPurple: "rgb(174, 128, 221)",
    gold: "rgb(244, 199, 44)",
    mediumDarkGray: "rgb(76, 76, 76)",
    mediumLightGray: "rgb(153, 153, 153)",
    slateBlue: "rgb(25, 127, 193)",
    sharkBlack: "rgb(51, 55, 59)",
    pastelGreen: "rgb(80, 181, 179)",
    pastelPink: "rgb(247, 152, 165)",
    duskBlue: "rgb(16, 91, 131)",
    paleBlue: "rgb(66, 146, 180)",
    crimsonRed: "rgb(213, 65, 37)",
    pineGreen: "rgb(129, 151, 128)",
    lightBlue: "rgb(80, 170, 240)",
    burntOrange: "rgb(235, 116, 37)",
    darkTeal: "rgb(39, 141, 165)",
    forestBrown: "rgb(163, 118, 93)",
    royalBlue: "rgb(59, 75, 185)",
    purpleNavy: "rgb(43, 44, 73)",
    lightBurnedOrange: "rgb(198, 85, 49)",
    berry: "rgb(175, 44, 77)",
    lightRed: "rgb(197, 97, 92)",
    honey: "rgb(255, 196, 63)",
    honeyComb: "rgb(248, 155, 46)",
    limeGreen: "rgb(69, 150, 71)",
    mist: "rgb(144, 175, 195)",
    stone: "rgb(54, 106, 133)",
    ice: "rgb(163, 213, 225)",
    glacier: "rgb(35, 148, 171)",
    plum: "rgb(164, 87, 109)",
    bloodOrange: "rgb(245, 105, 78)",
    deepBlue: "rgb(26, 106, 162)",
    deepSalmon: "rgb(215, 65, 55)",
    goldenRod: "rgb(234, 168, 48)",
    autumnRed: "rgb(168, 48, 36)",

    fuchsia: "rgb(240, 99, 140)",
    cherryBlossom: "rgb(255, 157, 201)",

    mysticPurple: "rgb(112, 88, 124)",
    mysticBlue: "rgb(46, 80, 119)",

    plasma: "rgb(144, 91, 180)",
    pumpkin: "rgb(253, 152, 52)",
    shadow: "rgb(71, 69, 72)",
    wine: "rgb(95, 40, 73)",
    straw: "rgb(254, 164, 53)",
    deepOcean: "rgb(25, 89, 165)",
}

const THEMES_BY_KEY: { [id: string]: Theme } = {
    earth: {
        name: "Earth",
        id: "earth",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.landGreen,
        pathColor: COLOR_NAMES.oceanBlue,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    // productRed: {
    //     name: "(RED)",
    //     id: "red",
    //     tileColor: COLOR_NAMES.tileGray,
    //     wallColor: COLOR_NAMES.slateGray,
    //     pathColor: COLOR_NAMES.cardinalRed
    // },

    royal: {
        name: "Royal",
        id: "royal",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.royalPurple,
        pathColor: COLOR_NAMES.gold,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    zen: {
        name: "Zen",
        id: "zen",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.mediumDarkGray,
        pathColor: COLOR_NAMES.mediumLightGray,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    sharkFin: {
        name: "Shark Fin",
        id: "sharkFin",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.sharkBlack,
        pathColor: COLOR_NAMES.slateBlue,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    forest: {
        name: "Forest",
        id: "forest",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.forestBrown,
        pathColor: COLOR_NAMES.pineGreen,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    dusk: {
        name: "Dusk",
        id: "dusk",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.duskBlue,
        pathColor: COLOR_NAMES.paleBlue,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    taos: {
        name: "Taos",
        id: "taos",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.darkTeal,
        pathColor: COLOR_NAMES.forestBrown,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    citrus: {
        name: "Citrus",
        id: "citrus",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.limeGreen,
        pathColor: COLOR_NAMES.gold,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    moth: {
        name: "Moth",
        id: "moth",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.slateGray,
        pathColor: COLOR_NAMES.royalPurple,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    berry: {
        name: "Berry",
        id: "berry",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.purpleNavy,
        pathColor: COLOR_NAMES.berry,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    harvest: {
        name: "Harvest",
        id: "harvest",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.lightBurnedOrange,
        pathColor: COLOR_NAMES.purpleNavy,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    honey: {
        name: "Honey",
        id: "honey",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.honeyComb,
        pathColor: COLOR_NAMES.honey,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    mist: {
        name: "Mist",
        id: "mist",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.stone,
        pathColor: COLOR_NAMES.mist,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    glacier: {
        name: "Glacier",
        id: "glacier",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.glacier,
        pathColor: COLOR_NAMES.ice,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    autumn: {
        name: "Autumn",
        id: "autumn",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.plum,
        pathColor: COLOR_NAMES.bloodOrange,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    elements: {
        name: "Elements",
        id: "elements",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.deepBlue,
        pathColor: COLOR_NAMES.deepSalmon,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    cherryBlossom: {
        name: "Cherry Blossom",
        id: "cherryBlossom",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.fuchsia,
        pathColor: COLOR_NAMES.cherryBlossom,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    mystique: {
        name: "Mystique",
        id: "mystique",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.mysticPurple,
        pathColor: COLOR_NAMES.deepBlue,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    spooky: {
        name: "Spooky",
        id: "spooky",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.shadow,
        pathColor: COLOR_NAMES.pumpkin,
        isUnlocked: UNLOCKED_INITIALLY,
    },

    novice: {
        name: "Novice",
        id: "novice",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.pineGreen,
        pathColor: COLOR_NAMES.landGreen,
        isUnlocked: (state) => {
            return state.levelsSolved.easy >= 200;
        }
    },

    advanced: {
        name: "Advanced",
        id: "advanced",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.deepBlue,
        pathColor: COLOR_NAMES.lightBlue,
        isUnlocked: (state) => {
            return state.levelsSolved.medium >= 200;
        }
    },

    expert: {
        name: "Expert",
        id: "expert",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.crimsonRed,
        pathColor: COLOR_NAMES.bloodOrange,
        isUnlocked: (state) => {
            return state.levelsSolved.hard >= 200;
        }
    },

    easterEgg: {
        name: "Easter Egg",
        id: "easterEgg",
        tileColor: COLOR_NAMES.tileGray,
        wallColor: COLOR_NAMES.pastelPink,
        pathColor: COLOR_NAMES.pastelGreen,
        isUnlocked: (state) => {
            return state.easterEggFound;
        }
    },
}

export const THEMES = Object.values(THEMES_BY_KEY);

export function themeById(id: string): Theme {
    return THEMES_BY_KEY[id];
}



