import React, {useEffect, useRef, useState} from "react";
import { Level, Theme} from "../../data";
import styled from "styled-components";
import { Footer, Header, Layout, Main, Title } from "../../layout";
import {IconButton} from "../IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { range } from "../../utils";
import {IconButtonSpacer} from "../IconButtonSpacer";
import {Button} from "../Button";

interface LevelSelectProps {
    level: Level;
    setLevel: (level: Level) => void;
    close: () => void;
    levelsSolved: { [difficulty: string]: number };
    theme: Theme;
}

export const LevelSelect: React.FC<LevelSelectProps> = ({
    level,
    setLevel,
    close,
    theme,
    levelsSolved
}) => {
    const [viewDifficulty, setViewDifficulty] = useState(level.difficulty);

    return <Layout>
        <Header>
            <IconButtonSpacer />
            <Title>Level Select</Title>
            <IconButton icon={faTimes} onClick={close} isDisabled={false} />
        </Header>
        <Main>
            <ListContainer>
                {range(0, levelsSolved[viewDifficulty] + 1).map((levelNumber) => {
                    return <LevelItem
                        key={`${viewDifficulty}/${levelNumber}`}
                        theme={theme}
                        levelNumber={levelNumber}
                        onSelect={() => {
                            setLevel({difficulty: viewDifficulty, number: levelNumber});
                            close();
                        }}
                        isSelected={levelNumber === level.number && viewDifficulty === level.difficulty}
                        isDisabled={levelsSolved[viewDifficulty] < levelNumber}
                        isScrollDefault={
                            level.difficulty === viewDifficulty
                                ? levelNumber === level.number
                                : levelNumber === levelsSolved[viewDifficulty]
                        }
                    />;
                })}
            </ListContainer>
        </Main>
        <Footer>
            <div/>
            <DifficultySelect>
                <Button onClick={() => setViewDifficulty("easy")} text="Easy" isDisabled={false} theme={theme} />
                <Button onClick={() => setViewDifficulty("medium")} text="Medium" isDisabled={false} theme={theme} />
                <Button onClick={() => setViewDifficulty("hard")} text="Hard" isDisabled={false} theme={theme} />
            </DifficultySelect>
            <div/>
        </Footer>
    </Layout>;
}

const DifficultySelect = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
`;

const ListContainer = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
`;

interface LevelItemProps {
    levelNumber: number;
    isSelected: boolean;
    isDisabled: boolean;
    onSelect: () => void;
    theme: Theme;
    isScrollDefault: boolean;
}

const LevelItem: React.FC<LevelItemProps> = ({ levelNumber, isSelected, isDisabled, onSelect, theme, isScrollDefault }) => {
    const ref = useRef<HTMLDivElement>(null);
    const classes = [];
    if (isDisabled) classes.push("disabled");
    if (isSelected) classes.push("selected");

    useEffect(() => {
        if (isScrollDefault) {
            ref.current?.scrollIntoView();
        }
    }, [isScrollDefault]);

    return <LevelItemDiv
        ref={ref}
        key={levelNumber}
        onClick={() => {
            if (!isDisabled) {
                onSelect();
            }
        }}
        theme={theme}
        className={classes.join(" ")}
    >
        Level {levelNumber + 1}
    </LevelItemDiv>;
}

const LevelItemDiv = styled.div<{ theme: Theme }>`
    width: 100%;
    padding: 10px;   
    
    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
    
    &:hover:not(.disabled):not(.selected) {
        background-color: #f5f5f5;
    }
    
    &.selected {
        color: white;
        background-color: ${({ theme }) => theme.wallColor };
    }
    
    &.disabled {
        color: gray;
        cursor: auto;
    }
    
    cursor: pointer;
`;