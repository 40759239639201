import styled from "styled-components";

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    user-select: none;
    height: 32px;
    background-color: #f5f5f5;
`

export const Header = styled(Controls)`
    border-bottom: 1px solid #efefef;
    box-shadow: 0px 1px 5px 0px #f5f5f5;
    grid-area: header;
`;

export const Footer = styled(Controls)`
    border-top: 1px solid #efefef;
    box-shadow: 0px -1px 5px 0px #f5f5f5;
    grid-area: footer;
    height: 32px;
    // border-bottom: 32px solid #f5f5f5;
`;

export const Title = styled.h3`
    margin-top: 5px;
    font-weight: normal;
`;

export const Layout = styled.div`
    display: grid;
    grid-template-areas: "header"
    "main"
    "footer";
    grid-template-rows: auto 1fr auto;
    height: 100%;
    /* mobile viewport bug fix */
    max-height: -webkit-fill-available;
`;

export const Main = styled.div`
    grid-area: main;
    display: flex;
    overflow: hidden;
    background-color: white;
`;