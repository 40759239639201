import React, {useEffect, useRef, useState} from "react";
import {Theme} from "../../data";
import styled from "styled-components";
import {IconButton} from "../IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import {useGrid} from "../../hooks";
import {getMiniGrid} from "./mini_grid";
import {BoardView} from "../BoardView";
import {IconButtonSpacer} from "../IconButtonSpacer";

interface ThemeSelectProps {
    theme: Theme;
    setTheme: (theme: Theme) => void;
    close: () => void;
    unlockedThemes: Theme[];
    setEasterEggFound: (easterEggFound: boolean) => void;
    isAutoCorneringEnabled: boolean;
    isAudioEnabled: boolean;
    isDoubleClickRestartEnabled: boolean;
}

export const ThemeSelect: React.FC<ThemeSelectProps> = ({
    theme: selectedTheme,
    setTheme,
    close,
    unlockedThemes,
    setEasterEggFound,
    isAutoCorneringEnabled,
    isAudioEnabled,
    isDoubleClickRestartEnabled
}) => {
    const solvedThemes = useRef(new Map<string, boolean>());

    const markSolved = (theme: Theme, isSolved: boolean) => {
        if (isSolved && solvedThemes.current.get(theme.id)) return;
        solvedThemes.current.set(theme.id, isSolved);
        if (unlockedThemes.every(theme => solvedThemes.current.get(theme.id))) {
            setEasterEggFound(true);
        }
    }

    return <Layout>
        <Header>
            <IconButtonSpacer />
            <Title>Themes</Title>
            <IconButton icon={faTimes} onClick={close} isDisabled={false} />
        </Header>
        <Main>
            <ListContainer>
                {unlockedThemes.map((theme) => {
                    return <ThemeItem
                        currentTheme={selectedTheme}
                        key={theme.id}
                        theme={theme}
                        onSelect={() => setTheme(theme)}
                        isSelected={theme.id === selectedTheme.id}
                        setIsSolved={(isSolved) => markSolved(theme, isSolved)}
                        isAutoCorneringEnabled={isAutoCorneringEnabled}
                        isAudioEnabled={isAudioEnabled}
                        isDoubleClickRestartEnabled={isDoubleClickRestartEnabled}
                    />;
                })}
            </ListContainer>
        </Main>
        <Footer />
    </Layout>;
}

const ListContainer = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
`;

interface LevelItemProps {
    theme: Theme;
    currentTheme: Theme;
    onSelect: () => void;
    isSelected: boolean;
    setIsSolved: (isSolved: boolean) => void;
    isAutoCorneringEnabled: boolean;
    isAudioEnabled: boolean;
    isDoubleClickRestartEnabled: boolean;
}

const ThemeItem: React.FC<LevelItemProps> = ({
     theme,
     isSelected,
     onSelect,
     currentTheme,
     setIsSolved,
     isAutoCorneringEnabled,
     isAudioEnabled,
     isDoubleClickRestartEnabled
}) => {
    const classes = [];
    if (isSelected) classes.push("selected");

    return <ThemeItemDiv
        onClick={onSelect}
        className={classes.join(" ")}
        theme={currentTheme}
    >
        <div>{theme.name}</div>
        <MiniGrid
            theme={theme}
            setIsSolved={setIsSolved}
            isAutoCorneringEnabled={isAutoCorneringEnabled}
            isAudioEnabled={isAudioEnabled}
            isDoubleClickRestartEnabled={isDoubleClickRestartEnabled}
        />
    </ThemeItemDiv>;
}

const ThemeItemDiv = styled.div<{ theme: Theme }>`
    padding: 10px;   
    display: flex;
    flex-direction: row;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    
    &:not(:last-child) {
        border-bottom: 1px solid #efefef;
    }
    
    &:hover:not(.disabled):not(.selected) {
        background-color: #f5f5f5;
    }
    
    &.selected {
        color: white;
        background-color: ${({ theme }) => theme.wallColor };
    }
    
    &.disabled {
        color: gray;
        cursor: auto;
    }
    
    cursor: pointer;
`;

interface MiniGridProps {
    setIsSolved: (isSolved: boolean) => void;
    theme: Theme;
    isAutoCorneringEnabled: boolean;
    isAudioEnabled: boolean;
    isDoubleClickRestartEnabled: boolean;
}

const MiniGrid: React.FC<MiniGridProps> = ({
    setIsSolved,
    theme,
    isAutoCorneringEnabled,
    isAudioEnabled,
    isDoubleClickRestartEnabled
}) => {
    const [grid] = useState(getMiniGrid);
    const {
        path,
        isSolved,
        move,
        clickCell,
        doubleClickGrid
    } = useGrid(grid, {
        isAutoCorneringEnabled,
        isAudioEnabled,
        isDoubleClickRestartEnabled
    });

    useEffect(() => {
        setIsSolved(isSolved);
    }, [isSolved, setIsSolved]);

    return <BoardContainer><BoardView
        theme={theme}
        grid={grid}
        path={path}
        onClickCell={clickCell}
        onDoubleClick={doubleClickGrid}
        onMove={move}
    /></BoardContainer>;
}

const BoardContainer = styled.div`
    width: 100px;
    height: 100px;
    background-color: white;
    padding: 2px;
`;