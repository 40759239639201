import React, {useRef} from "react";
import {Direction} from "../data";

interface UseSwipeProps {
    onSwipe: (direction: Direction) => void;
}

const SWIPE_THRESHOLD = 50; // px

export function useSwipe({ onSwipe }: UseSwipeProps) {
    const startX = useRef<number>();
    const startY = useRef<number>();
    const endX = useRef<number>();
    const endY = useRef<number>();

    const onTouchMove = (event: React.TouchEvent) => {
        if (!startX.current || !startY.current) {
            return;
        }

        const touch = event.touches[0];
        endX.current = touch.clientX;
        endY.current = touch.clientY;
    }

    const onTouchStart = (event: React.TouchEvent) => {
        const touch = event.touches[0];
        startX.current = touch.clientX;
        startY.current = touch.clientY;
    }

    const onTouchEnd = () => {
        if (!startX.current || !startY.current || !endX.current || !endY.current) {
            return;
        }

        const xDiff = startX.current - endX.current;
        const yDiff = startY.current - endY.current;

        if (Math.max(Math.abs(xDiff), Math.abs(yDiff)) < SWIPE_THRESHOLD) return;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                onSwipe(Direction.Left);
            } else {
                onSwipe(Direction.Right);
            }
        } else {
            if (yDiff > 0) {
                onSwipe(Direction.Up);
            } else {
                onSwipe(Direction.Down);
            }
        }

        startX.current = undefined;
        endX.current = undefined;
    }

    return { onTouchMove, onTouchStart, onTouchEnd };
}