import {useLocalStorage} from "./use_local_storage";
import {Difficulty, Score} from "../data";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useCallback, useEffect, useRef} from "react";
import {useDebounce} from "./use_debounce";

interface ScorePost extends Score {
    token: string;
}

function postScore(data: ScorePost) {
    return fetch('/.netlify/functions/score_post', {
        body: JSON.stringify(data),
        method: 'POST'
    }).then(response => {
        return response.json()
    })
}

function getScores(difficulty: Difficulty) {
    return fetch(`/.netlify/functions/scores_get?difficulty=${difficulty}`, {
        method: 'GET'
    }).then(response => {
        return response.json()
    })
}

function getScore(difficulty: Difficulty, token: string) {
    return fetch(`/.netlify/functions/score_get?difficulty=${difficulty}&token=${token}`, {
        method: 'GET'
    }).then(response => {
        return response.json()
    })
}

interface UseScoresResult {
    solveLevel: (difficulty: Difficulty, number: number) => void;
    levelsSolved: {
        easy: number;
        medium: number;
        hard: number;
    };
    refreshHighScores: () => void;
    scores: {
        easy: Score[];
        medium: Score[];
        hard: Score[];
    };
}

const SOLVED_LEVELS_KEY = "SOLVED_LEVELS";

const SCORES_QUERY_KEYS = {
    easy: "SCORES_EASY",
    medium: "SCORES_MEDIUM",
    hard: "SCORES_HARD"
}

const SCORE_QUERY_KEYS = {
    easy: "SCORE_EASY",
    medium: "SCORE_MEDIUM",
    hard: "SCORE_HARD"
}

interface UseScoresProps {
    recoveryToken: string;
    playerName: string;
    isLeaderboardEnabled: boolean;
}

export function useScores({ recoveryToken, playerName, isLeaderboardEnabled }: UseScoresProps): UseScoresResult {
    const [levelsSolved, setLevelsSolved] = useLocalStorage(SOLVED_LEVELS_KEY,{
        easy: 0,
        medium: 0,
        hard: 0
    });

    const { data: easyScores } = useQuery(SCORES_QUERY_KEYS.easy, () => getScores("easy"));
    const { data: mediumScores } = useQuery(SCORES_QUERY_KEYS.medium, () => getScores("medium"));
    const { data: hardScores } = useQuery(SCORES_QUERY_KEYS.hard, () => getScores("hard"));

    const { data: easyScore } = useQuery(SCORE_QUERY_KEYS.easy, () => getScore("easy", recoveryToken));
    const { data: mediumScore } = useQuery(SCORE_QUERY_KEYS.medium, () => getScore("medium", recoveryToken));
    const { data: hardScore } = useQuery(SCORE_QUERY_KEYS.hard, () => getScore("hard", recoveryToken));

    const queryClient = useQueryClient();

    const { mutate: solveLevelMutate } = useMutation("", postScore, {
        onSuccess: (_, { difficulty }) => {
            queryClient.invalidateQueries(SCORES_QUERY_KEYS[difficulty as Difficulty])
        },
    })

    const levelsSolvedRef = useRef(levelsSolved);
    levelsSolvedRef.current = levelsSolved;

    const debouncedName = useDebounce(playerName, 500);

    useEffect(() => {
        if (!isLeaderboardEnabled) return;
        for (const difficulty of ["easy", "medium", "hard"]) {
            solveLevelMutate({
                difficulty,
                number: levelsSolvedRef.current[difficulty as Difficulty],
                name: debouncedName,
                token: recoveryToken
            });
        }
    }, [debouncedName, recoveryToken, solveLevelMutate, isLeaderboardEnabled]);

    useEffect(() => {
        setLevelsSolved({
            easy: Math.max(easyScore?.data?.number || 0, levelsSolvedRef.current.easy),
            medium: Math.max(mediumScore?.data?.number || 0, levelsSolvedRef.current.medium),
            hard: Math.max(hardScore?.data?.number || 0, levelsSolvedRef.current.hard),
        });
    }, [easyScore, mediumScore, hardScore, setLevelsSolved]);

    const refreshHighScores = useCallback(() => {
        queryClient.invalidateQueries(SCORES_QUERY_KEYS.easy);
        queryClient.invalidateQueries(SCORES_QUERY_KEYS.medium);
        queryClient.invalidateQueries(SCORES_QUERY_KEYS.hard);
    }, [queryClient]);

    useEffect(() => {
        refreshHighScores();
    }, [queryClient, recoveryToken, refreshHighScores]);

    const solveLevel = (difficulty: Difficulty, number: number ) => {
        const newMax = Math.max(levelsSolved[difficulty], number + 1);
        setLevelsSolved({ ...levelsSolved, [difficulty]: newMax });
        if (!isLeaderboardEnabled) return;
        solveLevelMutate({
            difficulty,
            number: newMax,
            name: playerName,
            token: recoveryToken
        });
    }

    return {
        levelsSolved,
        refreshHighScores,
        solveLevel,
        scores: { easy: easyScores?.data || [], medium: mediumScores?.data || [], hard: hardScores?.data || [] }
    };
}