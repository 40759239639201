import React from "react";
import {Theme} from "../../data";
import styled from "styled-components";
import {IconButton} from "../IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import {IconButtonSpacer} from "../IconButtonSpacer";

interface CreditsProps {
    close: () => void;
}

export const Credits: React.FC<CreditsProps> = ({
    close
}) => {
    return <Layout>
        <Header>
            <IconButtonSpacer />
            <Title>Credits</Title>
            <IconButton icon={faTimes} onClick={close} isDisabled={false} />
        </Header>
        <Main>
            <SettingsList>
                <SettingDiv>
                    <Paragraph>
                        <Link href="https://twitter.com/rbxadam?lang=en" target="_blank">
                            Adam Miller
                        </Link> and <Link href="https://erich-friedman.github.io/" target="_blank">
                            Erich Friedman
                        </Link> originally dreamt up and created
                        this game for iOS in 2009.
                    </Paragraph>
                    <Paragraph>
                        I (Christopher Swenson) gave it a refresh in 2015, but didn't have time to maintain it as an
                        iOS app. Now it is a progressive web app for easy maintainability.
                    </Paragraph>
                </SettingDiv>
            </SettingsList>
        </Main>
        <Footer />
    </Layout>;
}

const SettingsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`;

const SettingDiv = styled.div<{ theme: Theme }>`
    padding: 10px 15px;   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 32px;
`;

const Paragraph = styled.p`
    line-height: 24px;
`;

const Link = styled.a`
    color: #1069e1;
    text-decoration: none;
`;
