import {useState} from "react";
import {Howl} from 'howler';

export interface UseSoundResult {
    play: () => void;
}

export function useSound(filePath: string): UseSoundResult {
    const [audio] = useState(new Howl({
        src: [filePath]
    }));
    const play = () => {
        audio.play();
    };

    return {
        play
    }
}