import {RefObject, useEffect, useState} from "react";

interface UseIsInteractingResult {
    isInteracting: boolean;
    onTouchStart: () => void;
    onMouseDown: () => void;
}

interface UseIsInteractingProps {
    ref: RefObject<HTMLElement>;
}

export function useIsInteracting({ ref }: UseIsInteractingProps): UseIsInteractingResult {
    const [isInteracting, setIsInteracting] = useState(false);

    const onTouchStart = () => {
        setIsInteracting(true);
    }

    const onMouseDown = () => {
        setIsInteracting(true);
    }

    useEffect(() => {
        const check = (event: TouchEvent | MouseEvent) => {
            if (event.target !== ref.current && event.target && !ref.current?.contains(event.target as HTMLElement)) {
                setIsInteracting(false);
            }
        }
        document.addEventListener("touchstart", check);
        document.addEventListener("mousedown", check);

        return () => {
            document.removeEventListener("touchstart", check);
            document.removeEventListener("mousedown", check);
        }
    }, [ref]);

    return { isInteracting, onTouchStart, onMouseDown };
}