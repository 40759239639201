import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {
    Direction,
    Grid,
    gridCells,
    gridColumnCount,
    gridRowCount,
    Path,
    Position,
    positionKey,
    Segment,
    segmentKey,
    Theme
} from "../../data";
import { useIsInteracting, useSwipe} from "../../hooks";

interface BoardViewProps {
    theme: Theme;
    grid: Grid;
    path: Path;
    onClickCell: (position: Position) => void;
    onDoubleClick: () => void;
    onMove: (direction: Direction) => void;
    hints?: Segment[];
}

const CELL_SIZE = 20;
const CELL_GAP = 1;

export const BoardView: React.FC<BoardViewProps> = ({
    grid,
    path,
    onClickCell,
    onMove,
    theme,
    hints
}) => {
    const cellWidth = gridColumnCount(grid);
    const cellHeight = gridRowCount(grid);
    const width = cellWidth * CELL_SIZE + (cellWidth - 1) * CELL_GAP;
    const height = cellHeight * CELL_SIZE + (cellHeight - 1) * CELL_GAP;
    const viewBox = `0 0 ${width} ${height}`;
    const outerRef = useRef<HTMLDivElement>(null);

    const {
        isInteracting,
        onTouchStart: interactTouchStart,
        onMouseDown,
    } = useIsInteracting({ ref: outerRef });

    useEffect(() => {
        if (!isInteracting) return;
        const onKeyPress = (event: KeyboardEvent) => {
            if (event.key === "ArrowLeft") {
                onMove(Direction.Left);
            } else if (event.key === "ArrowRight") {
                onMove(Direction.Right);
            } else if (event.key === "ArrowUp") {
                event.preventDefault();
                onMove(Direction.Up);
            } else if (event.key === "ArrowDown") {
                event.preventDefault();
                onMove(Direction.Down);
            }
        }
        document.addEventListener("keydown", onKeyPress);
        return () => document.removeEventListener("keydown", onKeyPress);
    }, [onMove, isInteracting]);

    const {
        onTouchStart: swipeTouchStart,
        onTouchEnd,
        onTouchMove
    } = useSwipe({ onSwipe: onMove })

    const onTouchStart = (event: React.TouchEvent) => {
        swipeTouchStart(event);
        interactTouchStart();
    }

    return <Container
        ref={outerRef}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        onMouseDown={onMouseDown}
    >
        <div/>
        <GridSvg viewBox={viewBox}>
            {gridCells(grid).map(({ cell, position }) => {
                const x = position.column * CELL_SIZE + position.column * CELL_GAP;
                const y = position.row* CELL_SIZE + position.row * CELL_GAP;
                const fill = cell === "empty" ? theme.tileColor : theme.wallColor;
                return <rect
                    key={positionKey(position)}
                    x={x}
                    y={y}
                    width={CELL_SIZE}
                    height={CELL_SIZE}
                    fill={fill}
                    onClick={() => onClickCell(position)}
                />;
            })}
            {path.map(segment => {
                const { x, y, width, height } = positionForSegment(segment);
                return <rect
                    key={segmentKey(segment)}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={theme.pathColor}
                    style={{ pointerEvents: "none" }}
                />;
            })}
            {hints && hints.map((hint) => {
                const {x, y, width, height} = positionForSegment(hint);
                return <HintRect
                    key={segmentKey(hint)}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={theme.pathColor}
                    style={{ pointerEvents: "none" }}
                />;
            })}
        </GridSvg>
        <div/>
    </Container>;
}

const HintRect = styled.rect`
    @keyframes pulse {
        0% { opacity: 0.2; }
        50% { opacity: 0.4; }
        100% { opacity: 0.2; }
    }

    animation: pulse 2s infinite ease-in-out;
`;

function positionForSegment(segment: Segment) {
    const topLeftMostEndpoint = segment.start.column < segment.end.column ? segment.start : segment.start.row < segment.end.row ? segment.start : segment.end;
    const topLeftColumn = topLeftMostEndpoint.column;
    const x = topLeftColumn * CELL_SIZE + topLeftColumn * CELL_GAP;
    const topLeftRow = topLeftMostEndpoint.row;
    const y = topLeftRow * CELL_SIZE + topLeftRow * CELL_GAP;
    const cellWidth = Math.abs(segment.end.column - segment.start.column) + 1;
    const width = cellWidth * CELL_SIZE + (cellWidth - 1) * CELL_GAP;
    const cellHeight = Math.abs(segment.end.row - segment.start.row) + 1;
    const height = cellHeight * CELL_SIZE + (cellHeight - 1) * CELL_GAP;
    return { x, y, width, height };
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    touch-action: none;
`;

const GridSvg = styled.svg`
    position: absolute;
    max-height: 100%;
    max-width: 100%;
`;