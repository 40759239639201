import { default as RAW_LEVELS } from "../levels.json";
import {Grid, gridColumnCount, gridRowCount} from "./Grid";
import {Level} from "./Level";
import {mulberry32} from "../utils";
import {gridGenerate} from "./generator";

interface LevelProps {
    grid: Grid
}

const SIZE_INCREASE_ODDS = 0.04;

export const PRE_GENERATED_LEVELS = RAW_LEVELS as { [difficulty: string]: LevelProps[] };

function gridSizeForLevel(level: Level): { rowCount: number, columnCount: number } {
    const levelsForDifficulty = PRE_GENERATED_LEVELS[level.difficulty];
    if (level.number < levelsForDifficulty.length) {
        const grid = levelsForDifficulty[level.number].grid;
        return {
            rowCount: gridRowCount(grid),
            columnCount: gridColumnCount(grid)
        }
    }
    const reference = levelsForDifficulty[levelsForDifficulty.length - 1];
    const seed = ["easy", "medium", "hard"].indexOf(level.difficulty);

    let random = mulberry32(seed);
    let rowCount = gridRowCount(reference.grid);
    let columnCount = gridColumnCount(reference.grid);
    for (let i = levelsForDifficulty.length; i < level.number; i++) {
        if (random(100) < SIZE_INCREASE_ODDS * 100) {
            if (random(rowCount + columnCount) < rowCount) {
                columnCount += 1;
            } else {
                rowCount += 1;
            }

        }
    }
    return { rowCount, columnCount };
}

export function gridForLevel(level: Level): Grid {
    let levelsForDifficulty = PRE_GENERATED_LEVELS[level.difficulty];
    if (level.number < levelsForDifficulty.length) {
        return levelsForDifficulty[level.number].grid;
    } else {
        const { rowCount, columnCount } = gridSizeForLevel(level);
        return gridGenerate(rowCount, columnCount, level.number);
    }
}