import {useSound} from "./use_sound";
import swipeSound from "../assets/sounds/blow-soft-two.wav";
import winSound from "../assets/sounds/marimba-high-roll-short-soft-no-delay.wav";

interface UseGameSoundsResult {
    playSwipe: () => void;
    playWin: () => void;
}

export function useGameSounds(): UseGameSoundsResult {
    const { play: playSwipe } = useSound(swipeSound);
    const { play: playWin } = useSound(winSound);

    return { playSwipe, playWin };
}