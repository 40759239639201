import styled from "styled-components";
import {IconButton} from "../IconButton";
import React from "react";
import {faForward} from "@fortawesome/free-solid-svg-icons";

export const IconButtonSpacer: React.FC = () => {
    return <Wrapper><IconButton icon={faForward} onClick={() => {}} isDisabled={true} /></Wrapper>
}

const Wrapper = styled.div`
    opacity: 0;
    color: transparent;
    visibility: hidden;
`;