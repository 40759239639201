import React, {useState} from "react";
import {Theme} from "../../data";
import styled from "styled-components";
import {IconButton} from "../IconButton";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Footer, Header, Layout, Main, Title} from "../../layout";
import {InlineInput, IconButtonSpacer, Button} from "..";

interface LevelSelectProps {
    recoveryToken: string;
    setRecoveryToken: (recoveryToken: string) => void;
    close: () => void;
    theme: Theme;
}

export const RecoverySettings: React.FC<LevelSelectProps> = ({
    recoveryToken,
    setRecoveryToken,
    close,
    theme
}) => {
    const [editRecoveryToken, setEditRecoveryToken] = useState("");
    const [error, setError] = useState<string | undefined>(undefined);

    const update = () => {
        if (!/[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}/g.test(editRecoveryToken)) {
            setError("Recovery token must be of the form XXXX-XXXX-XXXX using only alphanumeric characters (and hyphens).");
        } else {
            setError(undefined);
            setRecoveryToken(editRecoveryToken);
            close();
        }
    }

    const copyToken = () => {
        const el = document.createElement('textarea');
        el.value = recoveryToken;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    return <Layout>
        <Header>
            <IconButtonSpacer />
            <Title>Recovery Settings</Title>
            <IconButton icon={faTimes} onClick={close} isDisabled={false} />
        </Header>
        <Main>
            <SettingsList>
                <SettingDiv>
                    <Paragraph>Save this recovery token in case you lose your progress or want to transfer
                        to another device.</Paragraph>
                </SettingDiv>
                <SettingDiv>
                    <div>{recoveryToken}</div>
                    <Button onClick={copyToken} isDisabled={false} text="Copy" theme={theme} />
                </SettingDiv>
                <Separator />
                <SettingDiv>
                    <Paragraph>To recover scores from another device, enter a recovery token here. </Paragraph>
                </SettingDiv>
                <SettingDiv>
                    <div>Recovery Token</div>
                    <InlineInput value={editRecoveryToken} setValue={setEditRecoveryToken} />
                </SettingDiv>
                {error && <SettingDiv>
                    <ErrorMessage>{error}</ErrorMessage>
                </SettingDiv>}
                <SettingDiv>
                    <div />
                    <Button onClick={update} isDisabled={false} text="Save" theme={theme} />
                </SettingDiv>
            </SettingsList>
        </Main>
        <Footer />
    </Layout>;
}

const SettingsList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
`;

const SettingDiv = styled.div<{ theme: Theme }>`
    padding: 10px 15px;   
    display: flex;
    justify-content: space-between;
    min-height: 32px;
    align-items: center;
`;

const Separator = styled.div`
    width: 100%;
    border-bottom: 1px solid #efefef;
    margin-top: 10px;
`;

const Paragraph = styled.p`
    line-height: 24px;
`;

const ErrorMessage = styled.div`
    color: #cd0000;
`;
