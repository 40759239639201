import {Theme, themeInverse} from "../../data";
import {Button} from "../Button";
import React from "react";

interface ToggleButtonProps {
    value: boolean;
    setValue: (value: boolean) => void;
    theme: Theme;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ value, setValue, theme }) => {
    return <Button
        text={value ? "On" : "Off"}
        onClick={() => setValue(!value)}
        isDisabled={false}
        theme={value ? theme : themeInverse(theme) }
    />
}