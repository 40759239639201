import styled from "styled-components";
import React from "react";
import { Theme } from "../../data";

interface ButtonProps {
    text: string;
    onClick: () => void;
    isDisabled: boolean;
    theme?: Theme;
}

export const Button: React.FC<ButtonProps> = ({
    text,
    onClick,
    isDisabled,
    theme
}) => {
    return <StyledButton
        onClick={onClick}
        disabled={isDisabled}
        theme={theme}
    >
        {text}
    </StyledButton>
}

const StyledButton = styled.button<{ theme: Theme }>`
    border: none;
    outline: none;
    background-color: ${({ theme }) => theme.wallColor };
    color: white;
    border-radius: 4px;
    font-size: 14px;
    padding: 3px 10px;
    height: 30px;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.wallColor };
`;