import React, {useMemo, useState} from 'react';
import './App.css';
import { GamePage, HighScores, LevelSelect, Settings} from "./components";

import { gridForLevel, Level, Theme, THEMES} from "./data";
import {useLocalStorage, useGrid, useUnlockedThemes, useScores} from './hooks';
import {randomRecoveryToken} from './utils';
import {Tutorial} from "./components/Tutorial";

const CURRENT_LEVEL_KEY = "CURRENT_LEVEL";
const CURRENT_THEME_KEY = "CURRENT_THEME";
const AUTO_CORNERING_KEY = "AUTO_CORNERING";
const AUDIO_ENABLED_KEY = "AUDIO_ENABLED";
const LEADERBOARD_ENABLED_KEY = "LEADERBOARD_ENABLED";
const EASTER_EGG_FOUND_KEY = "EASTER_EGG_FOUND";
const RECOVERY_TOKEN_KEY = "RECOVERY_TOKEN";
const PLAYER_NAME_KEY = "PLAYER_NAME";
const DOUBLE_TAP_RESTART_KEY = "DOUBLE_TAP_RESTART";
const TUTORIAL_COMPLETED_KEY = "TUTORIAL_COMPLETED";

function App() {
    const [level, setLevel] = useLocalStorage<Level>(CURRENT_LEVEL_KEY, {
        difficulty: "easy",
        number: 0 }
    );

    const [theme, setTheme] = useLocalStorage<Theme>(CURRENT_THEME_KEY, THEMES[0]);

    const [isAutoCorneringEnabled, setIsAutoCorneringEnabled] = useLocalStorage(AUTO_CORNERING_KEY, true);
    const [, setIsDoubleClickRestartEnabled] = useLocalStorage(DOUBLE_TAP_RESTART_KEY, true);
    const [isAudioEnabled, setIsAudioEnabled] = useLocalStorage(AUDIO_ENABLED_KEY, true);
    const [isLeaderboardEnabled, setIsLeaderboardEnabled] = useLocalStorage(LEADERBOARD_ENABLED_KEY, true);
    const [isTutorialCompleted, setIsTutorialCompleted] = useLocalStorage(TUTORIAL_COMPLETED_KEY, false);

    const [easterEggFound, setEasterEggFound] = useLocalStorage(EASTER_EGG_FOUND_KEY, false);

    const [initialRecoveryToken] = useState(randomRecoveryToken);
    const [recoveryToken, setRecoveryToken] = useLocalStorage(RECOVERY_TOKEN_KEY, initialRecoveryToken);

    const [playerName, setPlayerName] = useLocalStorage(PLAYER_NAME_KEY, "Player");

    const grid = useMemo(() => gridForLevel(level), [level]);

    const {
        levelsSolved,
        solveLevel,
        scores,
        refreshHighScores
    } = useScores({ recoveryToken, playerName, isLeaderboardEnabled });

    const { unlockedThemes } = useUnlockedThemes({ levelsSolved, easterEggFound });

    const [view, setView] = useState("game");

    const {
        path,
        isSolved,
        restart,
        move,
        clickCell,
        doubleClickGrid
    } = useGrid(grid, {
        isAutoCorneringEnabled,
        isAudioEnabled,
        isDoubleClickRestartEnabled: false
    });

    if (!isTutorialCompleted) {
        return <Tutorial
            onClose={() => {
                setIsTutorialCompleted(true);
            }}
            isAudioEnabled={isAudioEnabled}
        />;
    } else if (view === "game") {
        return (
            <GamePage
                level={level}
                openLevelSelect={() => setView("levels")}
                openSettings={() => setView("settings")}
                openHighScores={() => setView("scores")}
                theme={theme}
                grid={grid}
                path={path}
                onClickCell={clickCell}
                onDoubleClick={doubleClickGrid}
                onMove={move}
                isSolved={isSolved}
                onAdvanceLevel={() => {
                    setLevel({ ...level, number: level.number + 1 });
                    solveLevel(level.difficulty, level.number)
                    restart();
                }}
                onRestart={restart}
            />
        );
    } else if (view === "levels") {
        return (
            <LevelSelect
                level={level}
                theme={theme}
                setLevel={(level) => {
                    setLevel(level);
                    restart();
                }}
                close={() => setView("game")}
                levelsSolved={levelsSolved}
            />
        )
    } else if (view === "settings") {
        return (
            <Settings
                unlockedThemes={unlockedThemes}
                setEasterEggFound={setEasterEggFound}
                theme={theme}
                setTheme={setTheme}
                isAutoCorneringEnabled={isAutoCorneringEnabled}
                setIsAutoCorneringEnabled={setIsAutoCorneringEnabled}
                isAudioEnabled={isAudioEnabled}
                setIsAudioEnabled={setIsAudioEnabled}
                isLeaderboardEnabled={isLeaderboardEnabled}
                setIsLeaderboardEnabled={setIsLeaderboardEnabled}
                isDoubleClickRestartEnabled={false}
                setIsDoubleClickRestartEnabled={setIsDoubleClickRestartEnabled}
                playerName={playerName}
                setPlayerName={setPlayerName}
                recoveryToken={recoveryToken}
                setRecoveryToken={setRecoveryToken}
                close={() => setView("game")}
            />
        )
    } else {
        return (
            <HighScores
                level={level}
                scores={scores}
                theme={theme}
                close={() => setView("game")}
                onRefresh={refreshHighScores}
            />
        );
    }
}

export default App;
